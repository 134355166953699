import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';

const ContactsPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryContactsPage: pageData,
  } = data;
  const { seo, title } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <h1>{title}</h1>
      </MainContainer>
    </PageWrapper>
  );
};

export default ContactsPageTemplate;

export const query = graphql`
  query AboutPageQuery($locale: String!) {
    datoCmsSymmetryContactsPage(locale: { eq: $locale }) {
      locale
      title
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`;
